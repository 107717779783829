.machine-specific-gallery {
	display: grid;
	width: 100%;
	overflow: hidden;
	overflow: auto;
	&__view-all-button {
		display: grid;
		justify-self: center;
		margin-top: 1rem;
		margin-bottom: 2rem;
		background: $primary-color;
		border: none;
		color: white;
		padding: 0.5rem 1rem;
		border-radius: 4px;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: darken($primary-color, 10%);
		}
	}
	&__grid {
		display: flex;
		position: relative;
	}
	&__image-wrapper {
		flex: 0 0 auto;
		width: 90%;
		max-width: 200px;
		margin-right: 1rem;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			opacity: 0.8;
		}
	}
	&__image {
		width: 100%;
		height: auto;
		object-fit: cover;
		border-radius: 4px;
	}
	&__view-all-modal {
		display: grid;
		position: fixed;
		align-items: flex-start;
		margin-top: 3.5rem;
		inset: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 100;
		overflow: auto;
		&__close {
			position: fixed;
			margin-top: 1rem;
			right: 1rem;
			background: rgba(0, 0, 0, 0.6);
			color: #fff;
			border: none;
			border-radius: 50%;
			width: 2.5rem;
			height: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.5rem;
			cursor: pointer;
			z-index: 121;
		}
		&__grid {
			display: grid;
			padding: 0.5rem;
			gap: 0.5rem;
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
			&__image-wrapper {
				cursor: pointer;
				max-width: 150px;
				&__image {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	&__view-individual-image-modal {
		display: grid;
		position: fixed;
		align-items: center;
		align-content: center;
		inset: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 120;
		overflow: auto;
		&__content {
			display: grid;
			position: relative;
			padding: 0.25rem;
			&__image-wrapper {
				display: grid;
				position: relative;
				height: max-content;
				&__arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: #fff;
					border: none;
					background: rgb(46, 46, 46);
					width: 2rem;
					height: 2rem;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					z-index: 121;
					&.left {
						left: 0.25rem;
					}
					&.right {
						right: 0.25rem;
					}
				}
				&__close {
					position: absolute;
					background: rgba(0, 0, 0, 0.6);
					color: #fff;
					border: none;
					border-radius: 50%;
					width: 1.5rem;
					height: 1.5rem;
					right: 0.5rem;
					top: 0.5rem;
					display: flex;
					align-items: center;
					align-content: center;
					justify-content: center;
					font-size: 1.5rem;
					cursor: pointer;
					z-index: 121;
				}
				&__image {
					width: 100%;
					height: 100%;
					object-fit: contain;
					max-height: 95vh;
				}
			}
		}
	}
}

@include responsive(small) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}

		&__view-all-modal {
			&__grid {
				display: grid;
				padding: 0.5rem;
				gap: 0.5rem;
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				&__image-wrapper {
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 85vh;
					}
				}
			}
		}
	}
}

@include responsive(medium) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
			max-width: 300px;
		}
		&__view-all-modal {
			&__grid {
				display: grid;
				padding: 0.5rem;
				gap: 0.5rem;
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				&__image-wrapper {
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 90vh;
					}
				}
			}
		}
	}
}

@include responsive(large) {
	.machine-specific-gallery {
		&__grid {
			display: flex;
			justify-content: space-between;
		}
		&__image-wrapper {
			max-width: 320px;
		}
		&__view-all-modal {
			&__grid {
				display: grid;
				padding: 0.5rem;
				gap: 0.5rem;
				grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
				&__image-wrapper {
					&__image {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		&__view-individual-image-modal {
			display: grid;
			position: fixed;
			align-items: center;
			align-content: center;
			inset: 0;
			background: rgba(0, 0, 0, 0.8);
			z-index: 120;
			overflow: auto;
			&__content {
				display: grid;
				justify-content: center;
				position: relative;
				padding: 0.25rem;
				width: 100%;
				&__image-wrapper {
					display: grid;
					position: relative;
					height: max-content;
					width: max-content;
					&__arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						color: #fff;
						border: none;
						background: rgb(46, 46, 46);
						width: 2rem;
						height: 2rem;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						z-index: 121;
						&.left {
							left: 0.25rem;
						}
						&.right {
							right: 0.25rem;
						}
					}
					&__close {
						position: absolute;
						background: rgba(0, 0, 0, 0.6);
						color: #fff;
						border: none;
						border-radius: 50%;
						width: 1.5rem;
						height: 1.5rem;
						right: 0.5rem;
						top: 0.5rem;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						font-size: 1.5rem;
						cursor: pointer;
						z-index: 121;
					}
					&__image {
						width: 100%;
						object-fit: contain;
						height: 80vh;
					}
				}
			}
		}
	}
}
